<template>
  <div class="d-flex flex-column chat-card overflow-hidden">
    <BackToChats text="Nuova chat"></BackToChats>
    <v-row class="justify-center align-center"> Inizia questa conversazione... </v-row>
    <chat-footer @sendMessage="sendMessage($event)"></chat-footer>
  </div>
</template>

<script>
import gql from "graphql-tag";
import BackToChats from "./BackToChats.vue";
import ChatFooter from "./ChatFooter.vue";
import { CHAT_TILE_FRAGMENT } from "./ChatTile.vue";

export default {
  props: ["user_id"],
  components: { ChatFooter, BackToChats },
  data() {
    return {
      msg: null,
    };
  },
  methods: {
    sendMessage(text) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation SendMessage($input: SendPrivateMessageInput!) {
              sendPrivateMessage(input: $input) {
                id
                chat {
                  ...chatTile
                }
              }
            }
            ${CHAT_TILE_FRAGMENT}
          `,
          variables: {
            input: {
              text,
              receiver_id: this.user_id,
            },
          },
        })
        .then(({ data }) => this.$emit("newChat", data.sendPrivateMessage.chat))
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style></style>
